@import 'src/styles/variables';

.link {
	display: flex;
	align-items: center;
	width: 200px + $padding * 2;
	padding: 15px $padding;
}

.logo {
	width: auto;
	height: 100%;
}
